<template>

    <div class="mb-3 card" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">

        <div>

            <v-container fluid>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierName"
                                      :label="$t('erp.lang_ware_supplier_name')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="SupplierCustID"
                                      :label="$t('customers.lang_customerID')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierStreet"
                                      :label="$t('generic.lang_street')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierStreetNo"
                                      :label="$t('localbee.lang_lbRestaurantStreetNo')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierZip"
                                      :label="$t('customers.lang_cust_zip')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierCity"
                                      :label="$t('settings.lang_city')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>


                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierCountry"
                                      :label="$t('generic.lang_country')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierContactPerson"
                                      :label="$t('generic.lang_ansprechpartner')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierContactPersonPhone"
                                      :label="$t('erp.lang_extensionContactPerson')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierPhone"
                                      :label="$t('generic.lang_telefone')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierFax"
                                      :label="$t('generic.lang_fax')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>

                </v-layout>
                <v-layout wrap>

                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      type="email"
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="suppliereMail"
                                      :label="$t('generic.lang_emailAdresse')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierWebsite"
                                      :label="$t('generic.lang_Website')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>

                    <v-flex md12 xs12>
                        <v-textarea outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="supplierNotes"
                                    :label="$t('generic.lang_internalNotes')"
                                    autocomplete="off"
                        >
                        </v-textarea>
                    </v-flex>
                </v-layout>

                <v-layout>
                    <v-flex style="text-align:right;">
                        <v-btn text color="error" @click="goBackToSuppliers">{{ $t('generic.lang_prev') }}</v-btn>
                        <v-btn color="success" :disabled="supplierName.length < 1" @click="addData">{{
                            $t('generic.lang_add') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>

        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {},
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                supplierName: "",
                SupplierCustID: "",
                supplierStreet: "",
                supplierStreetNo: "",
                supplierZip: "",
                supplierCity: "",
                supplierCountry: "",
                supplierContactPerson: "",
                supplierContactPersonPhone: "",
                supplierPhone: "",
                supplierFax: "",
                suppliereMail: "",
                supplierWebsite: "",
                supplierNotes: "",
            }
        },


        methods: {
            /*GO BACK TO SUPPLIER LIST */
            goBackToSuppliers: function () {
                this.$router.push('/erp/storage/supplier');
            },
            /*ADD NEW SUPPLIER */
            addData: function () {
                this.axios.post(ENDPOINTS.ERP.SUPPLIERS.CREATE, {

                    supplierName: this.supplierName,
                    supplierCustNo: this.SupplierCustID,
                    supplierStreetNo: this.supplierStreetNo,
                    supplierStreet: this.supplierStreet,
                    supplierZip: this.supplierZip,
                    supplierCity: this.supplierCity,
                    supplierCountry: this.supplierCountry,
                    supplierContactPerson: this.supplierContactPerson,
                    supplierContactPersonPhone: this.supplierContactPersonPhone,
                    supplierPhone: this.supplierPhone,
                    supplierFax: this.supplierFax,
                    supplierEMail: this.suppliereMail,
                    supplierWebsite: this.supplierWebsite,
                    supplierNotes: this.supplierNotes,

                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t("erp.supplierCreated"),
                            color: "success"
                        });
                        this.goBackToSuppliers();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },

        },
    }
</script>